import ApiService from "./api.service";

export const GET_TEST_SCENARIOS = "getTestScenarios";

export const SET_TEST_SCENARIOS = "setTestScenarios";

const state = {
  testScenarios: [],
};

const getters = {
  currentTestScenarios(state) {
    return state.testScenarios;
  },
};

const actions = {
  [GET_TEST_SCENARIOS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("/test-scenarios", params)
        .then(async ({ data }) => {
          await context.commit(SET_TEST_SCENARIOS, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_TEST_SCENARIOS](state, data) {
    if (data.list) {
      for (let i = 0; i < data.list.length; i++) {
        data.list[i].isOpen = false;
      }
      state.testScenarios = data;      
    }    
    return;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
