import ApiService from "./api.service";

export const GET_TRACK_DETAIL = "getTrackDetail";
export const SET_TRACK_DETAIL = "setTrackDetail";
export const GET_TRACK_LIST = "getTrackList";
export const GET_ALL_TRACKS = "getallTracks";
export const CHANGE_CATEGORY = "changeCategory";

export const SET_TRACTS = "setTracks";
export const SET_ALL_TRACKS = "setAllTracks";
export const SET_CATEGORIES = "setCategories";
export const SET_LOADING = "setLoading";

const state = {
  currentTrack: [],
  isLoading: true,
  tracks: [],
  allTracks: [],
  categories: [],
};

const getters = {
  currentTrack(state) {
    return state.currentTrack;
  },
  allTracks(state) {
    return state.allTracks;
  },
  isLoading(state) {
    return state.isLoading;
  },
  tracks(state) {
    return state.tracks;
  },
  categories(state) {
    return state.categories;
  },
};

const actions = {
  [GET_TRACK_DETAIL]({ commit }, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("/track/" + params.slug)
        .then(async ({ data }) => {
          await commit(SET_TRACK_DETAIL, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async [GET_TRACK_LIST]({ commit }) {
    try {
      await commit(SET_LOADING, true);

      const { data: categoryData } = await ApiService.get(
        "/track/list/category"
      );
      const categories = categoryData.data.data;
      commit(SET_CATEGORIES, categories);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async [GET_ALL_TRACKS]({ commit }, type) {
    try {
      await commit(SET_LOADING, true);

      // Mendapatkan data kategori
      const { data: categoryData } = await ApiService.get(
        "/track/list/category"
      );
      const categories = categoryData.data.data;

      // Filter kategori sesuai dengan jenis (type)
      const filteredCategories = categories.filter(
        (category) => category.type === type
      );
      commit(SET_CATEGORIES, filteredCategories);

      // Mendapatkan daftar semua track untuk setiap kategori yang difilter
      const allTracks = filteredCategories.flatMap(async (category) => {
        const { data: courseData } = await ApiService.get(
          "/track/list/" +
            category.type.replace("_", "-") +
            "?category=" +
            category.id
        );
        return courseData.data.data;
      });

      // Mendapatkan data dari semua track
      const data = await Promise.all(allTracks);
      commit(SET_ALL_TRACKS, data.flat());
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async [CHANGE_CATEGORY]({ commit }, payload) {
    try {
      await commit(SET_LOADING, true);
      const { data: courseData } = await ApiService.get(
        "/track/list/" +
          payload.type.replace("_", "-") +
          "?category=" +
          payload.id
      );
      await commit(SET_TRACTS, courseData.data.data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
};

const mutations = {
  [SET_TRACK_DETAIL](state, data) {
    state.currentTrack = data;
  },
  [SET_ALL_TRACKS](state, data) {
    state.allTracks = data;
    state.isLoading = false;
  },
  [SET_TRACTS](state, data) {
    state.tracks = data;
    state.isLoading = false;
  },
  [SET_CATEGORIES](state, data) {
    state.categories = data;
  },
  [SET_LOADING](state, data) {
    state.isLoading = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
