import ApiService from "./api.service";

export const GET_APP_TYPES = "getAppTypes";
export const GET_APP_METRICS = "getAppMetrics";
export const GET_APP_TYPE = "getAppType";

export const SET_APP_TYPES = "setAppTypes";
export const SET_APP_METRICS = "setAppMetrics";
export const SET_APP_TYPE = "setAppType";

// const convertSlug = {
//   1: "youtube",
//   2: "calculator",
//   3: "todo",
//   4: "api-todo",
//   5: "mobile-todo",
//   8: "pos-system"
// };

const alertMessage = {
  submissionNotStarted: "Please wait until the challenge starts",
  submissionClosed: "Submission for this assesment has been closed",
  unauthorizedUser: "You must login first to submit on this challenge",
  notDebuggable:
    "Please upload APK build file with debug version to run the test using Flutter",
  belowtargetSdk: "Target SDK Version must contain version 30",
};

const formLabel = {
  PLAYGROUND: {
    name: "Description",
    nameMaxLength: 70,
    url: "Submit URL",
    urlPlaceholder: "Type your site url here",
    hashtag: "Hashtag",
    hashtagPlaceholder: "Type a hashtag",
    deadline: "Deadline Submission",
  },
  LOGIC: {
    name: "Description",
    nameMaxLength: 70,
    url: "Submit URL",
    urlPlaceholder: "Type your site url here",
    hashtag: "Hashtag",
    hashtagPlaceholder: "Type a hashtag",
    deadline: "Deadline Submission",
  },
  FE: {
    name: "Description",
    nameMaxLength: 70,
    url: "Submit URL",
    urlPlaceholder: "Type your site url here",
    hashtag: "Hashtag",
    hashtagPlaceholder: "Type a hashtag",
    deadline: "Deadline Submission",
  },
  BE: {
    name: "Description",
    nameMaxLength: 70,
    url: "Docker Hub Image",
    urlPlaceholder: "Example : username/your-image",
    hashtag: "Hashtag",
    hashtagPlaceholder: "Type a hashtag",
    deadline: "Deadline Submission",
    database: "Database",
  },
  MOBILE: {
    name: "Description",
    nameMaxLength: 70,
    url: "Upload Apk Files",
    urlPlaceholder: "",
    hashtag: "Hashtag",
    hashtagPlaceholder: "Type a hashtag",
    deadline: "Deadline Submission",
    packageName: "Package Name",
    driver: "Framework",
    driverPlaceholder: "Select a framework",
    drivers: [
      {
        name: "Native",
        value: "native",
        // availableAtAppId: [5, 49],
      },
      {
        name: "React Native",
        value: "react-native",
        // availableAtAppId: [5, 49],
      },
      {
        name: "Flutter",
        value: "flutter",
        // availableAtAppId: [5, 8, 49],
      },
    ],
  },
};

const state = {
  appTypes: [],
  appType: {},
  appMetrics: {},
};

const getters = {
  currentAppTypes(state) {
    return state.appTypes;
  },
  currentAppType(state) {
    return state.appType;
  },
  currentAppMetrics(state) {
    return state.appMetrics;
  },
};

const actions = {
  [GET_APP_TYPES](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("/app-types", params)
        .then(async ({ data }) => {
          await context.commit(SET_APP_TYPES, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_APP_METRICS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("/app-names/metrics/" + params)
        .then(async ({ data }) => {
          await context.commit(SET_APP_METRICS, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_APP_TYPE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("/app-types/" + params)
        .then(async ({ data }) => {
          await context.commit(SET_APP_TYPE, data);
          resolve(data);
        })
        .catch((err) => {
          if (err.response.status == 404) {
            resolve({});
          } else {
            reject(err);
          }
        });
    });
  },
};

const mutations = {
  [SET_APP_TYPES](state, data) {
    // let i = 0;
    // for (const d of data) {
    //   data[i].slug = convertSlug[d.id];
    //   i += 1;
    // }
    state.appTypes = data;
    return;
  },
  [SET_APP_METRICS](state, data) {
    state.appMetrics = data;
    return;
  },
  [SET_APP_TYPE](state, data) {
    // data.slug = convertSlug[data.id];
    data.alert_message = alertMessage;
    data.form_label = formLabel[data.type];
    state.appType = data;
    return;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
