import ApiService from "./api.service";
import ApiServiceV1 from "./v1/api.service";

export const GET_CHALLENGER_PROFILE = "GET_CHALLENGER_PROFILE";
export const GET_CHALLENGER_ACHIEVEMENTS = "GET_CHALLENGER_ACHIEVEMENTS";
export const GET_DAY_SUBMISSION = "GET_DAY_SUBMISSION";
export const GET_TIME_SUBMISSION = "GET_TIME_SUBMISSION";
export const GET_SKILLS = "GET_SKILLS";

export const SET_CHALLENGER_PROFILE_LOADING = "SET_CHALLENGER_PROFILE_LOADING";
export const SET_CHALLENGER_PROFILE_DATA = "SET_CHALLENGER_PROFILE_DATA";
export const SET_CHALLENGER_ACHIEVEMENTS_LOADING =
  "SET_CHALLENGER_ACHIEVEMENTS_LOADING";
export const SET_CHALLENGER_ACHIEVEMENTS_DATA =
  "SET_CHALLENGER_ACHIEVEMENTS_DATA";
export const RESET_CHALLENGER_STATES = "RESET_CHALLENGER_STATES";

export const SET_DAY_SUBMISSION = "SET_DAY_SUBMISSION";
export const SET_TIME_SUBMISSION = "SET_TIME_SUBMISSION";

export const SET_SKILLS = "SET_SKILLS";

const state = {
  loading: false,
  data: null,
  achievementsLoading: false,
  achievementsData: [],
  daySubmission: [],
  timeSubmission: [],
  skills: [],
};

const getters = {
  profileLoading(state) {
    return state.loading;
  },
  profileData(state) {
    return state.data;
  },
  achievementsLoading(state) {
    return state.achievementsLoading;
  },
  achievementsData(state) {
    return state.achievementsData;
  },
  daySubmission(state) {
    return state.daySubmission;
  },
  timeSubmission(state) {
    return state.timeSubmission;
  },
  skills(state) {
    return state.skills;
  },
};

const actions = {
  [GET_CHALLENGER_PROFILE](context, emp_id) {
    context.commit(RESET_CHALLENGER_STATES);
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          context.commit(SET_CHALLENGER_PROFILE_LOADING, true);
          const request = await ApiService.get(`/profile/${emp_id}`);
          const { data } = request;
          await context.commit(SET_CHALLENGER_PROFILE_DATA, data);
          resolve(request);
        } catch (err) {
          reject(err);
        } finally {
          context.commit(SET_CHALLENGER_PROFILE_LOADING, false);
        }
      })();
    });
  },
  [GET_CHALLENGER_ACHIEVEMENTS](context, emp_id) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          context.commit(SET_CHALLENGER_ACHIEVEMENTS_LOADING, true);
          const request = await ApiService.get(
            `/profile/${emp_id}/list-achievement`
          );
          const { data } = request;
          await context.commit(SET_CHALLENGER_ACHIEVEMENTS_DATA, data);
          resolve(request);
        } catch (err) {
          reject(err);
        } finally {
          context.commit(SET_CHALLENGER_ACHIEVEMENTS_LOADING, false);
        }
      })();
    });
  },
  //   get day submission
  [GET_DAY_SUBMISSION](context, emp_id) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          // change base url
          ApiServiceV1.init();
          // request time
          const request = await ApiServiceV1.get(
            `/submission/profile-group-by-weekly-day?emp_id=${emp_id}`
          );
          const { data } = request;
          await context.commit(SET_DAY_SUBMISSION, data.data);
          resolve(request);
        } catch (err) {
          reject(err);
        }
      })();
    });
  },
  //   get time submission
  [GET_TIME_SUBMISSION](context, emp_id) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          // change base url
          ApiServiceV1.init();

          // request time
          const request = await ApiServiceV1.get(
            `/submission/profile-group-by-time-of-day?emp_id=${emp_id}`
          );
          const { data } = request;
          await context.commit(SET_TIME_SUBMISSION, data.data);
          resolve(request);
        } catch (err) {
          reject(err);
        }
      })();
    });
  },
  //   get skills
  [GET_SKILLS](context, emp_id) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          ApiService.init();
          // request time
          const request = await ApiService.get(`/profile/${emp_id}/list-skill`);
          const { data } = request;
          await context.commit(SET_SKILLS, data.skills);
          resolve(request);
        } catch (err) {
          reject(err);
        }
      })();
    });
  },
};

const mutations = {
  [SET_CHALLENGER_PROFILE_LOADING](state, loading) {
    state.loading = loading;
    return;
  },
  [SET_CHALLENGER_PROFILE_DATA](state, data) {
    state.data = data;
    return;
  },
  [SET_CHALLENGER_ACHIEVEMENTS_LOADING](state, loading) {
    state.achievementsLoading = loading;
    return;
  },
  [SET_CHALLENGER_ACHIEVEMENTS_DATA](state, data) {
    state.achievementsData = data;
    return;
  },
  [SET_DAY_SUBMISSION](state, data) {
    state.daySubmission = data;
    return;
  },
  [SET_TIME_SUBMISSION](state, data) {
    state.timeSubmission = data;
    return;
  },
  [SET_SKILLS](state, data) {
    state.skills = data;
    return;
  },
  [RESET_CHALLENGER_STATES](state) {
    // state.loading = false;
    state.data = null;
    // state.achievementsLoading = false;
    state.achievementsData = [];
    return;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
