// const customIcons = {
//   iconSetting: {
//     path: "@/assets/icon/icon-setting.svg"
//   }
// }

export default {
  colors: {
    main: "#29aafe",
    buttonNavbar: "#ffc35a",
    hoverButtonNavbar: "#325c8c",
    fontMain: "#4a4a4a",
    fontWarning: "#856404",
    breadcrumbNotActive: "#333333",
    breadcrumbActive: "#777",
    danger: "#F92424",
    dangerLight: "#FFF1F1",
    bgMain: "#EEF1F5",
    dividerColor: "#d3d3d3",
    borderBox: "#c7c7c7",
    premiumColor: "#F8A541",
    activeMenu: "#16ABF8",
    activeMenuOther: "#a4e0ff",
    activeMenuPagination: "rgba(16,171,248,0.1)",
    bgSocialBadge: "#f4f4f4",
    facebookColor: "#4267B2",
    twitterColor: "#00ACEE",
    instagramColor: "#CD486B",
    whatsappColor: "#128C7E",
    buttonSecondary: "#EFEFEF",
    buttonSecondaryColor: "#555555",
    breadcrumbNotActive2: "#C7C7C7",
    breadcrumbActive2: "#4A4A4A",
    fontInformation: "#888888",
    fontCountService: "#858686",
    fontLabel: "#1297EC",
    fontCopyEmail: "#A4A4A4",
    bgButtonSecondary3: "rgba(74,74,74,0.05)",
    buttonSecondary3: "rgba(74,74,74,0.4)",
    comingSoon: "rgba(22, 171, 248, 0.5)",
    bgCodeCompetitionBtn: "#0078B7",
    bgStackBadge: "rgba(255,255,255,0.2)",
    bgTestList: "rgba(22,171,248,0.05)",
    borderTest: "rgba(22,171,248,0.15)",
    headerTable: "#C4C4C4",
    linkColor: "#2B5AA8",
    passedColor: "#00BE63",
    failedColor: "#FF2D2D",
    bgWhite: "#FFFFFF",
    bgWaiting: "#F88589",
    bgWarning: "#fff3cd",
    bgButtonSecondary: "rgba(00, 00, 00, 0.1)",
    bgPassedState: "rgba(00, 190, 99, 0.1)",
    bgFailedState: "rgba(255, 00, 00, 0.1)",
    bgInviteWaiting: "#F49C1E",
    bgInvitePassed: "#ED4C5C",
    bgInviteSuccess: "#00A790",
    bgButtonClub: "#0278B7",
  },
  textStyles: {
    body: {
      fontFamily: "Montserrat, sans-serif",
    },
  },
  // icons: {
  //   extend: {
  //     ...customIcons
  //   }
  // }
};
