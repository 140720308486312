// store/modules/telegram.js
import ApiService from "./api.service";

export const POST_TELEGRAM_ACTIVATION = "postTelegramActivation";

const state = {};

const getters = {};

const actions = {
  [POST_TELEGRAM_ACTIVATION](context, token) {
    return new Promise((resolve, reject) => {
      ApiService.post("/user-employee/connect-telegram", { token })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
