import ApiService from "./api.service";

export const GET_LEADERBOARDS = "getNewLeaderboards";
export const GET_LEADERBOARDS_OVERVIEW = "getNewLeaderboards_OVERVIEW";
export const GET_LEADERBOARDS_INITIAL = "getNewLeaderboards_INITIAL";
export const GET_LEADERBOARDS_FILTERS = "getNewLeaderboards_FILTERS";
export const LOADMORE_LEADERBOARDS = "loadmoreNewLeaderboards";

export const SET_LEADERBOARDS = "setNewLeaderboards";
export const SET_LEADERBOARDS_LIST = "setNewLeaderboards_LIST";
export const SET_LEADERBOARDS_OVERVIEW = "setNewLeaderboards_OVERVIEW";
export const SET_LEADERBOARDS_LOADING = "setNewLeaderboards_LOADING";
export const SET_LEADERBOARDS_FILTERS = "setNewLeaderboards_FILTERS";
export const SET_LEADERBOARDS_ACTIVE_FILTER =
  "setNewLeaderboards_ACTIVE_FILTER";
export const SET_LEADERBOARDS_ACTIVE_FILTER_GROUP =
  "setNewLeaderboards_ACTIVE_FILTER_GROUP";
export const RESET_LEADERBOARDS = "resetNewLeaderboards";
export const SET_LEADERBOARDS_PAGE = "setNewLeaderboards_PAGE";
export const SET_LEADERBOARDS_LIMIT = "setNewLeaderboards_LIMIT";
export const SET_LEADERBOARDS_TOTAL = "setNewLeaderboards_TOTAL";

const state = {
  leaderboards: {},
  leaderboardsList: [],
  userRank: null,
  leaderboardsOverview: null,
  leaderboardsPage: 1,
  leaderboardsLimit: 10,
  leaderboardsTotal: 0,
  leaderboardsFilters: [],
  leaderboardsActiveFilter: {
    type: "main",
    id: null,
    name: "",
  },
  leaderboardsActiveFilterGroup: "all",
  leaderboardsLoading: false,
};

const getters = {
  currentNewLeaderboards(state) {
    return state.leaderboards;
  },
  currentNewLeaderboardsList(state) {
    return state.leaderboardsList;
  },
  currentNewUserRank(state) {
    return state.userRank;
  },
  currentNewLeaderboardsOverview(state) {
    return state.leaderboardsOverview;
  },
  currentNewLeaderboardsPage(state) {
    return state.leaderboardsPage;
  },
  currentNewLeaderboardsLimit(state) {
    return state.leaderboardsLimit;
  },
  currentNewLeaderboardsTotal(state) {
    return state.leaderboardsTotal;
  },
  currentNewLeaderboardsFilters(state) {
    return state.leaderboardsFilters;
  },
  currentNewLeaderboardsActiveFilters(state) {
    return state.leaderboardsActiveFilter;
  },
  currentNewLeaderboardsActiveGroup(state) {
    return state.leaderboardsActiveFilterGroup;
  },
  currentNewLeaderboardsLoading(state) {
    return state.leaderboardsLoading;
  },
};

const actions = {
  [GET_LEADERBOARDS]({ state, commit }, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("/rank/" + params.id, {
        page: params?.page ?? state.leaderboardsPage,
        limit: state.leaderboardsLimit,
        type: state.leaderboardsActiveFilter.type,
        type_id: state.leaderboardsActiveFilter.id,
        group: state.leaderboardsActiveFilterGroup,
        rank: params.rank,
      })
        .then(async ({ data }) => {
          await commit(SET_LEADERBOARDS, data);
          await commit(SET_LEADERBOARDS_LIST, data.data);
          await commit(SET_LEADERBOARDS_LOADING, false);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_LEADERBOARDS_OVERVIEW]({ state, commit }, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("/rank/" + params.id + "/overview", {
        type: state.leaderboardsActiveFilter.type,
        type_id: state.leaderboardsActiveFilter.id,
        group: state.leaderboardsActiveFilterGroup,
        rank: params.rank,
      })
        .then(async ({ data }) => {
          await commit(SET_LEADERBOARDS_OVERVIEW, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async [LOADMORE_LEADERBOARDS](context, params) {
    const page = context.getters.currentNewLeaderboardsPage + 1;
    await context.commit(SET_LEADERBOARDS_PAGE, page);
    await context.dispatch(GET_LEADERBOARDS, params);
  },
  [GET_LEADERBOARDS_INITIAL](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("/rank/" + params.id + "/initial")
        .then(async ({ data: { data } }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_LEADERBOARDS_FILTERS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("/rank/" + params.id + "/type")
        .then(async ({ data: { data } }) => {
          context.commit(SET_LEADERBOARDS_FILTERS, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_LEADERBOARDS](state, data) {
    state.leaderboards = data;
  },
  [SET_LEADERBOARDS_LIST](state, data) {
    if (state.leaderboards.meta.currentPage > 1) {
      data.leaderBoard.map((item) => {
        state.leaderboardsList.push(item);
      });
    } else {
      state.leaderboardsList = state.leaderboards.data.leaderBoard;
    }

    if (data.userRank?.length) {
      const user = data.userRank[0];
      const maxPage = Math.ceil(user.rank / state.leaderboardsLimit);
      if (state.leaderboardsPage < maxPage) {
        state.userRank = user;
      } else {
        state.userRank = null;
      }
    }
  },
  [SET_LEADERBOARDS_OVERVIEW](state, data) {
    state.leaderboardsOverview = data;
    return;
  },
  [SET_LEADERBOARDS_FILTERS](state, data) {
    state.leaderboardsFilters = data;
  },
  [SET_LEADERBOARDS_LOADING](state, data) {
    state.leaderboardsLoading = data;
  },
  [SET_LEADERBOARDS_ACTIVE_FILTER](state, payload) {
    state.leaderboardsActiveFilter = payload || [];
    return;
  },
  [SET_LEADERBOARDS_ACTIVE_FILTER_GROUP](state, payload) {
    state.leaderboardsActiveFilterGroup = payload || [];
    return;
  },
  [SET_LEADERBOARDS_PAGE](state, value) {
    state.leaderboardsPage = parseInt(value) || 1;

    if (state.userRank) {
      const maxPage = Math.ceil(state.userRank.rank / state.leaderboardsLimit);
      if (maxPage >= value) {
        state.userRank = null;
      }
    }
    return;
  },
  [SET_LEADERBOARDS_LIMIT](state, value) {
    state.leaderboardsLimit = parseInt(value) || 10;
    return;
  },
  [SET_LEADERBOARDS_TOTAL](state, value) {
    state.leaderboardsTotal = parseInt(value) || 0;
    return;
  },
  [RESET_LEADERBOARDS](state) {
    state.leaderboardsPage = 1;
    state.leaderboardsLimit = 10;
    state.leaderboardsTotal = 0;
    state.leaderboardsActiveFilter = {
      type: "main",
      id: null,
      name: "",
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
