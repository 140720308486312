import ApiService from "./api.service";

export const POST_COMMENT = "postComment";
export const POST_COMMENT_UPDATE = "postCommentUpdate";

export const GET_COMMENTS = "getComments";
export const GET_USER_SUGGESTIONS = "getUserSuggestions";
export const GET_HASHTAG_SUGGESTIONS = "getHashtagSuggestions";

export const SET_COMMENTS = "setComments";
export const SET_SELECTED_COMMENT = "setSelectedComment";
export const SET_USER_SUGGESTIONS = "setUserSuggestions";
export const SET_HASHTAG_SUGGESTIONS = "setHashtagSuggestions";
export const SET_TOGGLE_COMMENTS_MODAL = "toggleCommentsModal";

export const REMOVE_COMMENT = "removeComment";

export const RESET_SELECTED_COMMENT = "resetSelectedComment";

const state = {
  comments: [],
  userSuggestions: [],
  hashtagSuggestions: [],
  selectedComment: {
    id: 0,
    body: "",
  },
  isShowCommentModal: false,
};

const getters = {
  currentComments(state) {
    return state.comments;
  },
  currentSelectedComment(state) {
    return state.selectedComment;
  },
  currentCommentModalStatus(state) {
    return state.isShowCommentModal;
  },
  currentUserSuggestions(state) {
    return state.userSuggestions;
  },
  currentHashtagSuggestions(state) {
    return state.hashtagSuggestions;
  },
};

const actions = {
  [GET_COMMENTS]({ commit }, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("/comment/" + params.id)
        .then(async ({ data }) => {
          await commit(SET_COMMENTS, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [GET_USER_SUGGESTIONS]({ commit }, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/comment/${params.id}/users`)
        .then(async ({ data }) => {
          await commit(SET_USER_SUGGESTIONS, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [GET_HASHTAG_SUGGESTIONS]({ commit }, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/comment/${params.id}/hashtags`)
        .then(async ({ data }) => {
          await commit(SET_HASHTAG_SUGGESTIONS, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [POST_COMMENT](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post("/comment/" + params.id, params.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_COMMENT_UPDATE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put("/comment/" + params.id, params.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [REMOVE_COMMENT](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.delete("/comment/" + params.id)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_COMMENTS](state, data) {
    state.comments = data;
  },
  [SET_TOGGLE_COMMENTS_MODAL](state, data) {
    state.isShowCommentModal = data;
  },
  [SET_SELECTED_COMMENT](state, data) {
    state.selectedComment = data;
  },
  [SET_USER_SUGGESTIONS](state, data) {
    state.userSuggestions = data;
  },
  [SET_HASHTAG_SUGGESTIONS](state, data) {
    state.hashtagSuggestions = data;
  },
  [RESET_SELECTED_COMMENT](state) {
    state.selectedComment = {
      id: 0,
      body: "",
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
