import ApiService from "./api.service";

export const GET_CLUBS = "getClubs";
export const GET_OFFICIAL_CLUBS = "getOfficialClubs";
export const POST_CLUB = "postClub";
export const PUT_CLUB = "putClub";
export const DELETE_CLUB = "deleteClub";
export const SET_CLUBS = "setClubs";
export const SET_OFFICIAL_CLUBS = "setOfficialClubs";
export const POST_CLUB_IMAGE = "postClubImage";
export const GET_CLUB_DETAIL = "getClubDetail";
export const SET_CLUB_DETAIL = "setClubDetail";
export const POST_CLUB_INVITE = "postClubInvite";
export const GET_CLUB_LEADERBOARDS = "getClubLeaderboards";
export const SET_CLUB_LEADERBOARDS = "setClubLeaderboards";
export const GET_CLUB_LEADERS_LAST_MONTH = "getClubLeadersLastMonth";
export const SET_CLUB_LEADERS_LAST_MONTH = "setClubLeadersLastMonth";
export const GET_CLUB_MEMBERS = "getClubMembers";
export const SET_CLUB_MEMBERS = "setClubMembers";
export const GET_CLUB_ACTIVITIES = "getClubActivities";
export const SET_CLUB_ACTIVITIES = "setClubActivities";
export const GET_CLUB_REQUESTS = "getClubRequests";
export const SET_CLUB_REQUESTS = "setClubRequests";
export const POST_CLUB_REQUEST = "postClubRequest";
export const POST_CLUB_REQUEST_PROCESS = "postClubRequestProcess";
export const GET_CLUB_REQUESTS_PENDING = "getClubRequestsPending";
export const SET_CLUB_REQUESTS_PENDING = "setClubRequestsPending";
export const GET_CLUB_REQUESTS_TOTAL = "getClubRequestsTotal";
export const SET_CLUB_REQUESTS_TOTAL = "setClubRequestsTotal";
export const POST_CLUB_REMOVE_MEMBER = "postClubRemoveMember";
export const POST_CLUB_LEAVE_MEMBER = "postClubLeaveMember";
export const POST_CLUB_MEMBER_PROMOTION = "postClubMemberPromotion";
export const POST_CLUB_MEMBER_DEMOTION = "postClubMemberDemotion";
export const GET_CLUB_TOTAL = "getClubTotal";
export const GET_CLUB_TOTAL_JOINED = "getClubTotalJoined";
export const SET_CLUB_TOTAL = "setClubTotal";
export const SET_CLUB_TOTAL_JOINED = "setClubTotalJoined";
export const GET_CLUB_POSTS = "getClubPosts";
export const GET_CLUB_POST_DETAIL = "getClubPostDetail";
export const POST_CLUB_POST = "postClubPost";
export const PUT_CLUB_POST = "putClubPost";
export const DELETE_CLUB_POST = "deleteClubPost";
export const SET_CLUB_POSTS = "setClubPosts";
export const SET_CLUB_POST_DETAIL = "setClubPostDetail";

const state = {
  clubs: [],
  clubFilterTag: [],
  officialClubs: [],
  clubPosts: [],
  clubTotal: 0,
  clubTotalJoined: 0,
  currentClub: null,
  currentClubPost: null,
  currentClubLeaderboards: null,
  currentClubLeadersLastMonth: null,
  currentClubMembers: null,
  currentClubActivities: null,
  currentClubRequests: null,
  currentClubRequestsPending: null,
  requestsTotal: null,
};

const getters = {
  clubs(state) {
    return state.clubs;
  },
  clubFilterTag(state) {
    return state.clubFilterTag;
  },
  officialClubs(state) {
    return state.officialClubs;
  },
  clubPosts(state) {
    return state.clubPosts;
  },
  clubTotal(state) {
    return state.clubTotal;
  },
  clubTotalJoined(state) {
    return state.clubTotalJoined;
  },
  currentClub(state) {
    return state.currentClub;
  },
  currentClubPost(state) {
    return state.currentClubPost;
  },
  currentClubLeaderboards(state) {
    return state.currentClubLeaderboards;
  },
  currentClubLeadersLastMonth(state) {
    return state.currentClubLeadersLastMonth;
  },
  currentClubMembers(state) {
    return state.currentClubMembers;
  },
  currentClubActivities(state) {
    return state.currentClubActivities;
  },
  currentClubRequests(state) {
    return state.currentClubRequests;
  },
  requestsTotal(state) {
    return state.requestsTotal;
  },
};

const actions = {
  [GET_CLUBS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("/club", params.data)
        .then(({ data }) => {
          context.commit(SET_CLUBS, data);
          resolve(data);
        })
        .catch((err) => {
          console.error("Error fetching jobs:", err);
          reject(err);
        });
    });
  },
  [GET_OFFICIAL_CLUBS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/club?appId=${params.data.appId}`)
        .then(({ data }) => {
          context.commit(SET_OFFICIAL_CLUBS, data);
          resolve(data);
        })
        .catch((err) => {
          console.error("Error fetching jobs:", err);
          reject(err);
        });
    });
  },
  [GET_CLUB_TOTAL](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("/club", { myClub: false })
        .then(({ data }) => {
          context.commit(SET_CLUB_TOTAL, data);
          resolve(data);
        })
        .catch((err) => {
          console.error("Error fetching jobs:", err);
          reject(err);
        });
    });
  },
  [GET_CLUB_TOTAL_JOINED](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("/club", { myClub: true })
        .then(({ data }) => {
          context.commit(SET_CLUB_TOTAL_JOINED, data);
          resolve(data);
        })
        .catch((err) => {
          console.error("Error fetching jobs:", err);
          reject(err);
        });
    });
  },
  [POST_CLUB_IMAGE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post("/upload-image", params, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_CLUB](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post("/club", params.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PUT_CLUB](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put("/club/" + params.id, params.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DELETE_CLUB](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.delete("/club/" + params.id).catch((err) => {
        reject(err);
      });
    });
  },
  [GET_CLUB_POSTS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("/club/" + params.id + "/post", params.data)
        .then(({ data }) => {
          context.commit(SET_CLUB_POSTS, data);
          resolve(data);
        })
        .catch((err) => {
          console.error("Error fetching jobs:", err);
          reject(err);
        });
    });
  },
  [GET_CLUB_POST_DETAIL](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        "/club/" + params.id + "/post/" + params.postId,
        params.data
      )
        .then(({ data }) => {
          context.commit(SET_CLUB_POST_DETAIL, data);
          resolve(data);
        })
        .catch((err) => {
          console.error("Error fetching jobs:", err);
          reject(err);
        });
    });
  },
  [POST_CLUB_POST](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post("/club/" + params.id + "/post", params.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PUT_CLUB_POST](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put("/club/post/" + params.id, params.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DELETE_CLUB_POST](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.delete("/club/post/" + params.id).catch((err) => {
        reject(err);
      });
    });
  },
  [POST_CLUB_INVITE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post("/club/" + params.slug + "/invite", params.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async [GET_CLUB_DETAIL](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("/club/" + params.slug)
        .then(({ data }) => {
          context.commit(SET_CLUB_DETAIL, data);
          resolve(data);
        })
        .catch((err) => {
          console.error("Error fetching jobs:", err);
          reject(err);
        });
    });
  },
  async [GET_CLUB_LEADERBOARDS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("/club/" + params.slug + "/leaderboard", params.data)
        .then(({ data }) => {
          context.commit(SET_CLUB_LEADERBOARDS, data);
          resolve(data);
        })
        .catch((err) => {
          console.error("Error fetching jobs:", err);
          reject(err);
        });
    });
  },
  async [GET_CLUB_LEADERS_LAST_MONTH](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("/club/" + params.slug + "/leaderboard", params.data)
        .then(({ data }) => {
          context.commit(SET_CLUB_LEADERS_LAST_MONTH, data);
          resolve(data);
        })
        .catch((err) => {
          console.error("Error fetching jobs:", err);
          reject(err);
        });
    });
  },
  async [GET_CLUB_MEMBERS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("/club/" + params.slug + "/member", params.data)
        .then(({ data }) => {
          context.commit(SET_CLUB_MEMBERS, data);
          resolve(data);
        })
        .catch((err) => {
          console.error("Error fetching jobs:", err);
          reject(err);
        });
    });
  },
  async [GET_CLUB_ACTIVITIES](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("/club/" + params.slug + "/activity", params.data)
        .then(({ data }) => {
          context.commit(SET_CLUB_ACTIVITIES, data);
          resolve(data);
        })
        .catch((err) => {
          console.error("Error fetching jobs:", err);
          reject(err);
        });
    });
  },
  async [GET_CLUB_REQUESTS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("/club/" + params.slug + "/request", params.data)
        .then(({ data }) => {
          context.commit(SET_CLUB_REQUESTS, data);
          resolve(data);
        })
        .catch((err) => {
          console.error("Error fetching jobs:", err);
          reject(err);
        });
    });
  },
  [POST_CLUB_REQUEST](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post("/club/" + params.slug + "/request", params.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async [GET_CLUB_REQUESTS_PENDING](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("/club/baru" + "/request/total", params.data)
        .then(({ data }) => {
          context.commit(SET_CLUB_REQUESTS_PENDING, data);
          resolve(data);
        })
        .catch((err) => {
          console.error("Error fetching jobs:", err);
          reject(err);
        });
    });
  },
  async [POST_CLUB_REQUEST_PROCESS](context, params) {
    try {
      const { data } = await ApiService.post(
        `/club/${params.slug}/request/process`,
        params.data
      );
      return data;
    } catch (error) {
      console.error("Error processing club request:", error);
      throw error;
    }
  },
  async [POST_CLUB_REQUEST_PROCESS](context, params) {
    try {
      const { data } = await ApiService.post(
        `/club/${params.slug}/request/process`,
        params.data
      );
      return data;
    } catch (error) {
      console.error("Error processing club request:", error);
      throw error;
    }
  },
  async [GET_CLUB_REQUESTS_TOTAL](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("/club/request/total", state)
        .then(({ data }) => {
          context.commit(SET_CLUB_REQUESTS_TOTAL, data);
          resolve(data);
        })
        .catch((err) => {
          console.error("Error fetching jobs:", err);
          reject(err);
        });
    });
  },
  async [POST_CLUB_REMOVE_MEMBER](context, params) {
    try {
      const { data } = await ApiService.post(
        `/club/${params.slug}/member/${params.memberid}/drop`,
        params.data
      );
      return data;
    } catch (error) {
      console.error("Error processing club request:", error);
      throw error;
    }
  },
  async [POST_CLUB_LEAVE_MEMBER](context, params) {
    try {
      const { data } = await ApiService.post(
        `/club/${params.slug}/leave`,
        params.data
      );
      return data;
    } catch (error) {
      console.error("Error processing club request:", error);
      throw error;
    }
  },
  async [POST_CLUB_MEMBER_PROMOTION](context, params) {
    try {
      const { data } = await ApiService.post(
        `/club/${params.slug}/member/${params.memberid}/promotion`,
        params.data
      );
      return data;
    } catch (error) {
      console.error("Error processing club request:", error);
      throw error;
    }
  },
  async [POST_CLUB_MEMBER_DEMOTION](context, params) {
    try {
      const { data } = await ApiService.post(
        `/club/${params.slug}/member/${params.memberid}/demotion`,
        params.data
      );
      return data;
    } catch (error) {
      console.error("Error processing club request:", error);
      throw error;
    }
  },
};

const mutations = {
  [SET_CLUBS](state, data) {
    state.clubs = data.data;
  },
  [SET_OFFICIAL_CLUBS](state, data) {
    state.officialClubs = data.data;
  },
  [SET_CLUB_POSTS](state, data) {
    state.clubPosts = data.data;
  },
  [SET_CLUB_TOTAL](state, data) {
    state.clubTotal = data.data.meta.total;
  },
  [SET_CLUB_TOTAL_JOINED](state, data) {
    state.clubTotalJoined = data.data.meta.total;
  },
  [SET_CLUB_DETAIL](state, data) {
    state.currentClub = data.data;
  },
  [SET_CLUB_POST_DETAIL](state, data) {
    state.currentClubPost = data.data;
  },
  [SET_CLUB_LEADERBOARDS](state, data) {
    state.currentClubLeaderboards = data.data;
  },
  [SET_CLUB_LEADERS_LAST_MONTH](state, data) {
    state.currentClubLeadersLastMonth = data.data;
  },
  [SET_CLUB_MEMBERS](state, data) {
    state.currentClubMembers = data.data;
  },
  [SET_CLUB_ACTIVITIES](state, data) {
    state.currentClubActivities = data.data;
  },
  [SET_CLUB_REQUESTS](state, data) {
    state.currentClubRequests = data.data;
  },
  [SET_CLUB_REQUESTS_PENDING](state, data) {
    state.currentClubRequestsPending = data.data;
  },
  [SET_CLUB_REQUESTS_TOTAL](state, data) {
    state.requestsTotal = data.data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
