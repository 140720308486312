import ApiService from "./api.service";

export const GET_TEST_INVITATIONS = "getTestInvitations";

export const SET_TEST_INVITATIONS = "setTestInvitations";

const state = {
  testInvitations: [],
};

const getters = {
  currentTestInvitations(state) {
    return state.testInvitations;
  },
};

const actions = {
  [GET_TEST_INVITATIONS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/user-employee/${params.id}/invitation`)
        .then(async ({ data }) => {
          await context.commit(SET_TEST_INVITATIONS, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_TEST_INVITATIONS](state, data) {
    state.testInvitations = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
