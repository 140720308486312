import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { FETCH_JWT } from "@/store/auth.module";
// import config from "@/ConfigProvider";
// import Swal from "sweetalert2";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/views/layout/LayoutCompetition.vue"),
    children: [
      {
        path: "/",
        name: "Home",
        component: () => import("@/views/Home.vue"),
        meta: { haveAuthenticated: true },
      },
      {
        path: "/arena/:slug",
        name: "Arena",
        component: () => import("@/views/pages/Arena.vue"),
        meta: { haveAuthenticated: true, showPopupWhenUnauthenticated: true },
      },
      {
        path: "/challenge/:slug",
        name: "Competition",
        component: () => import("@/views/pages/Competition.vue"),
        meta: { haveAuthenticated: true, showPopupWhenUnauthenticated: true },
      },
      {
        path: "/challenge/:slug/arena-:arena",
        name: "CompetitionArena",
        component: () => import("@/views/pages/Competition.vue"),
        meta: { haveAuthenticated: true, showPopupWhenUnauthenticated: true },
      },
      {
        path: "/challenge/:slug/metrics",
        name: "Competition Metrics",
        component: () => import("@/views/pages/Metrics.vue"),
        meta: { haveAuthenticated: true, showPopupWhenUnauthenticated: true },
      },
      {
        path: "/track/:slug",
        name: "Detail Track",
        component: () => import("@/views/pages/DetailTrack.vue"),
        meta: { haveAuthenticated: true },
      },
      {
        path: "/track/:track/challenge/:slug",
        name: "Challenge Track",
        component: () => import("@/views/pages/Competition.vue"),
        meta: { haveAuthenticated: true, showPopupWhenUnauthenticated: true },
      },
      {
        path: "/telegram/:slug",
        name: "Telegram",
        component: () => import("@/views/pages/Telegram.vue"),
        meta: { haveAuthenticated: true }
      },
      // {
      //   path: "/profile/:emp_id",
      //   name: "Profile",
      //   component: () => import("@/views/pages/Profile.vue"),
      // },
      {
        path: "/profile/:emp_id",
        name: "Profile",
        component: () => import("@/views/pages/ProfileDetail.vue"),
      },
      {
        path: "/track-:type-:category",
        name: "Track List",
        component: () => import("@/views/pages/ListTrack.vue"),
      },
      {
        path: "/job/:job_id/report",
        name: "Report Result",
        component: () => import("@/views/pages/Report.vue"),
        meta: { haveAuthenticated: true },
      },
      {
        path: "/job/:job_id/comment/:comment_id/report",
        name: "Report Comment",
        component: () => import("@/views/pages/Report.vue"),
        meta: { haveAuthenticated: true },
      },
      {
        path: "/clubs",
        name: "Club",
        component: () => import("@/views/pages/ListClub.vue"),
        meta: { haveAuthenticated: true },
      },
      {
        path: "/track/:track/clubs",
        name: "Track Club",
        component: () => import("@/views/pages/ListClub.vue"),
        meta: { haveAuthenticated: true },
      },
      {
        path: "/clubs/new",
        name: "CreateClub",
        component: () => import("@/views/pages/CreateClub.vue"),
        meta: { haveAuthenticated: true },
      },
      {
        path: "/clubs/:slug",
        name: "DetailClub",
        component: () => import("@/views/pages/DetailClub.vue"),
        meta: { haveAuthenticated: true },
      },
      {
        path: "/clubs/:slug/activity",
        name: "ActivityClub",
        component: () => import("@/views/pages/DetailClub.vue"),
        meta: { haveAuthenticated: true },
      },
      {
        path: "/clubs/:slug/leaderboard",
        name: "LeaderboardClub",
        component: () => import("@/views/pages/DetailClub.vue"),
        meta: { haveAuthenticated: true },
      },
      {
        path: "/clubs/:slug/members",
        name: "Members Club",
        component: () => import("@/views/pages/DetailClub.vue"),
        meta: { haveAuthenticated: true },
      },
      {
        path: "/track/:track/clubs/:slug/members",
        name: "Track Members Club",
        component: () => import("@/views/pages/DetailClub.vue"),
        meta: { haveAuthenticated: true },
      },
      {
        path: "/clubs/:slug/posts",
        name: "PostsClub",
        component: () => import("@/views/pages/DetailClub.vue"),
        meta: { haveAuthenticated: true },
      },
      {
        path: "/clubs/:slug/edit",
        name: "EditClub",
        component: () => import("@/views/pages/EditClub.vue"),
        meta: { haveAuthenticated: true },
      },
      {
        path: "/clubs/:slug/posts/new",
        name: "CreatePostClub",
        component: () => import("@/views/pages/CreatePost.vue"),
        meta: { haveAuthenticated: true },
      },
      {
        path: "/clubs/:slug/posts/:id",
        name: "DetailPostClub",
        component: () => import("@/views/pages/DetailPost.vue"),
        meta: { haveAuthenticated: true },
      },
      {
        path: "/clubs/:slug/posts/:id/edit",
        name: "EditPostClub",
        component: () => import("@/views/pages/EditPost.vue"),
        meta: { haveAuthenticated: true },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/views/layout/LayoutNonHeader.vue"),
    children: [
      {
        path: "/job/:id",
        name: "Detail Job",
        component: () => import("@/views/pages/DetailJob.vue"),
      },
      {
        path: "/track/:track/job/:id",
        name: "Track Detail Job",
        component: () => import("@/views/pages/DetailJob.vue"),
      },
      // this routing for share to socmed
      {
        path: "/job/share/:id",
        name: "Detail Job Share",
        component: () => import("@/views/pages/DetailJob.vue"),
      },
      {
        path: "/challenge/share/:slug",
        name: "Competition Share",
        component: () => import("@/views/pages/Competition.vue"),
      },
      {
        path: "/log/:id",
        name: "Detail Log",
        component: () => import("@/views/pages/LogJob.vue"),
      },
      {
        path: "/track/:track/log/:id",
        name: "Track Detail Log",
        component: () => import("@/views/pages/LogJob.vue"),
      },
      {
        path: "/metrics/:id",
        name: "Metrics Log",
        component: () => import("@/views/pages/MetricsJob.vue"),
      },
      {
        path: "/leaderboard/:slug",
        name: "Leaderboard",
        component: () => import("@/views/pages/LeaderboardNew.vue"),
      },
      {
        path: "track/:track/leaderboard/:slug",
        name: "Track Leaderboard",
        component: () => import("@/views/pages/LeaderboardNew.vue"),
      },
      // {
      //   path: "/leaderboard/:id",
      //   name: "Leaderboard",
      //   component: () => import("@/views/pages/Leaderboard.vue"),
      // },
      {
        path: "/hashtag/:id",
        name: "Hashtags",
        component: () => import("@/views/pages/Hashtag.vue"),
      },
      {
        path: "/logic-result/:id",
        name: "Logic Test Result Detail",
        component: () => import("@/views/pages/LogicResult.vue"),
      },
      {
        path: "/runner-status",
        name: "Runner Status",
        component: () => import("@/views/pages/RunnerStatus.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/views/layout/LayoutBlank.vue"),
    children: [
      // this routing for capturing certificate from backend
      {
        path: "/job/certificate/:id",
        name: "Detail Job Certificate",
        component: () => import("@/views/pages/Certificate.vue"),
      }
    ],
  },
  {
    path: "*",
    name: "404",
    component: () => import("@/views/pages/Error/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  try {
    if (to?.query?.token) {
      await store.dispatch(FETCH_JWT, to?.query?.token);
    } else {
      await store.dispatch(FETCH_JWT);
    }
  } catch (e) {
    console.log(e);
  }

  next();

  // if (to.matched.some((record) => record.meta.haveAuthenticated)) {
  //   // let verified = true;
  //   try {
  //     if (to?.query?.token) {
  //       await store.dispatch(FETCH_JWT, to?.query?.token);
  //     } else {
  //       await store.dispatch(FETCH_JWT);
  //     }
  //   } catch (err) {
  //     // verified = false;
  //     // Swal.fire({
  //     //   title: "Oops . .",
  //     //   text: err.message,
  //     //   icon: "error",
  //     //   confirmButtonClass: "btn btn-secondary",
  //     //   heightAuto: false,
  //     // });
  //   }

  //   next();

  //   // if (verified) {
  //   //   await store.dispatch(VERIFY_AUTH).then(() => {
  //   //     if (!store.getters.isAuthenticated) {
  //   //       const nextUrl = encodeURIComponent(
  //   //         `/redirect?url=${window.location.origin + to.fullPath}`
  //   //       );
  //   //       if (
  //   //         to.matched.some(
  //   //           (record) => record.meta.showPopupWhenUnauthenticated
  //   //         )
  //   //       ) {
  //   //         (async () => {
  //   //           const { isConfirmed } = await Swal.fire({
  //   //             title: "Oops . .",
  //   //             text: `Maaf, anda perlu login di GetHired.id terlebih dahulu. Akses halaman ini kembali setelah anda melakukan Login pada GetHired.id`,
  //   //             icon: "error",
  //   //             confirmButtonClass: "btn btn-primary",
  //   //             confirmButtonText: "Login",
  //   //             heightAuto: false,
  //   //           });

  //   //           if (isConfirmed)
  //   //             window.location = `${config.value(
  //   //               "GETHIRED_WEB_URL"
  //   //             )}/signin?next=${nextUrl}`;
  //   //         })();
  //   //       } else {
  //   //         window.location.href =
  //   //           config.value("GETHIRED_WEB_URL") + `/signin?next=${nextUrl}`;
  //   //       }
  //   //       return;
  //   //     } else {
  //   //       next();
  //   //     }
  //   //   });
  //   // }
  // } else {
  //   next();
  // }
});

export default router;
