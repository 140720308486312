import ApiService from "./api.service";
import router from "@/router";

export const GET_LEADERBOARDS = "getLeaderboards";
export const GET_LEADERBOARDS_LIST = "getLeaderboards_LIST";
export const GET_LEADERBOARDS_OVERVIEW = "getLeaderboards_OVERVIEW";
export const GET_LEADERBOARDS_HASHTAG_FILTERS =
  "getLeaderboards_HASHTAG_FILTERS";
export const HANDLE_LEADERBOARDS_HASHTAG_ACTIVE_FILTERS =
  "handleLeaderboards_HASHTAG_ACTIVE_FILTERS";
export const LOADMORE_LEADERBOARDS = "loadmoreLeaderboards";
export const GET_LEADERBOARDS_METRICS = "getLeaderboards_METRICS";

export const RESET_LEADERBOARDS = "resetLeaderboards";
export const SET_LEADERBOARDS = "setLeaderboards";
export const SET_LEADERBOARDS_LOADING = "setLeaderboardsLoading";
export const SET_LEADERBOARDS_LIST_LOADING = "setLeaderboardsListLoading";
export const SET_LEADERBOARDS_PAGE = "setLeaderboards_PAGE";
export const SET_LEADERBOARDS_LIMIT = "setLeaderboards_TOTAL";
export const SET_LEADERBOARDS_TOTAL = "setLeaderboards_LIMIT";
export const SET_LEADERBOARDS_LIST = "setLeaderboardsList";
export const SET_LEADERBOARDS_OVERVIEW = "setLeaderboardsOverview";
export const SET_LEADERBOARDS_HASHTAG_FILTERS = "setLeaderboardsHashtagFilters";
export const SET_LEADERBOARDS_HASHTAG_ACTIVE_FILTERS =
  "setLeaderboardsHashtagActiveFilters";
export const SET_LEADERBOARDS_METRICS = "setLeaderboards_METRICS";

const numberWithPoint = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

const state = {
  leaderboardsLoading: false,
  leaderboardsListLoading: false,
  leaderboardsPage: 1,
  leaderboardsLimit: 10,
  leaderboardsTotal: 0,
  leaderboards: [],
  leaderboardsList: [],
  leaderboardsOverview: null,
  leaderboardsHashtagFilters: [],
  leaderboardsHashtagActiveFilters: [],
  leaderboardsMetrics: [],
};

const getters = {
  currentLeaderboards(state) {
    return state.leaderboards;
  },
  currentLeaderboardsMetrics(state) {
    return state.leaderboardsMetrics;
  },
  currentLeaderboardsPage(state) {
    return state.leaderboardsPage;
  },
  currentLeaderboardsLimit(state) {
    return state.leaderboardsLimit;
  },
  currentLeaderboardsTotal(state) {
    return state.leaderboardsTotal;
  },
  currentLeaderboardsList(state) {
    return state.leaderboardsList;
  },
  currentLeaderboardsOverview(state) {
    return state.leaderboardsOverview;
  },
  currentLeaderboardsHashtagFilters(state) {
    return state.leaderboardsHashtagFilters;
  },
  currentLeaderboardsHashtagActiveFilters(state) {
    return state.leaderboardsHashtagActiveFilters;
  },
  currentLeaderboardsLoading(state) {
    return state.leaderboardsLoading;
  },
  currentLeaderboardsListLoading(state) {
    return state.leaderboardsListLoading;
  },
};

const actions = {
  [GET_LEADERBOARDS_HASHTAG_FILTERS](context, appTypeId) {
    return new Promise((resolve, reject) => {
      ApiService.get("/hashtags/popular", { appTypeId })
        .then(async ({ data: { data } }) => {
          await context.commit(SET_LEADERBOARDS_HASHTAG_FILTERS, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_LEADERBOARDS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("/leaderboard/" + params)
        .then(async ({ data }) => {
          context.commit(SET_LEADERBOARDS, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_LEADERBOARDS_METRICS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/leaderboard/${params}/user-metrics`)
        .then(async ({ data }) => {
          await context.commit(SET_LEADERBOARDS_METRICS, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_LEADERBOARDS_LIST](context) {
    context.commit(SET_LEADERBOARDS_LIST_LOADING, true);
    return new Promise((resolve, reject) => {
      let hashtags = context.getters.currentLeaderboardsHashtagActiveFilters;
      let appTypeId = context.rootGetters.currentAppType.id;
      let params = {
        page: context.getters.currentLeaderboardsPage,
        limit: context.getters.currentLeaderboardsLimit,
      };
      if (hashtags.length) params["hashtag"] = hashtags;
      ApiService.get("/leaderboard/" + appTypeId, params)
        .then(async ({ data }) => {
          await context.commit(SET_LEADERBOARDS_LIST, data);
          resolve(data);
          context.commit(SET_LEADERBOARDS_LIST_LOADING, false);
        })
        .catch((err) => {
          context.commit(SET_LEADERBOARDS_LIST_LOADING, false);
          reject(err);
        });
    });
  },
  [GET_LEADERBOARDS_OVERVIEW](context) {
    return new Promise((resolve, reject) => {
      let appTypeId = context.rootGetters.currentAppType.id;

      let params = {};
      let hashtags = context.getters.currentLeaderboardsHashtagActiveFilters;
      if (hashtags.length) params["hashtag"] = hashtags;
      ApiService.get("/leaderboard/" + appTypeId + "/overview", params)
        .then(async ({ data }) => {
          context.commit(SET_LEADERBOARDS_OVERVIEW, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [HANDLE_LEADERBOARDS_HASHTAG_ACTIVE_FILTERS](context, value) {
    const current = context.getters.currentLeaderboardsHashtagActiveFilters;
    const findIndex = current.indexOf(value);
    findIndex >= 0 ? current.splice(findIndex, 1) : current.push(value);
    router.push({
      name: "Leaderboard",
      params: { id: router.currentRoute.params.id },
      query: current.length > 0 ? { tags: current.join(",") } : {},
    });
    // context.commit(SET_LEADERBOARDS_HASHTAG_ACTIVE_FILTERS, current);
    // context.commit(SET_LEADERBOARDS_PAGE, 1);
    // context.commit(SET_LEADERBOARDS_LIMIT, 10);
    // context.commit(SET_LEADERBOARDS_TOTAL, 0);
    // (async() => {
    //   context.commit(SET_LEADERBOARDS_LOADING, true);
    //   await context.dispatch(GET_LEADERBOARDS_OVERVIEW);
    //   context.commit(SET_LEADERBOARDS_LOADING, false);
    //   context.dispatch(GET_LEADERBOARDS_LIST);
    // })();
  },
  async [LOADMORE_LEADERBOARDS](context) {
    await context.commit(
      SET_LEADERBOARDS_PAGE,
      context.getters.currentLeaderboardsPage + 1
    );
    await context.dispatch(GET_LEADERBOARDS_LIST);
  },
  [RESET_LEADERBOARDS](context) {
    // context.commit(SET_LEADERBOARDS_HASHTAG_FILTERS, []);
    context.commit(SET_LEADERBOARDS_HASHTAG_ACTIVE_FILTERS, []);
    context.commit(SET_LEADERBOARDS_PAGE, 1);
    context.commit(SET_LEADERBOARDS_LIMIT, 10);
    context.commit(SET_LEADERBOARDS_TOTAL, 0);
  },
};

const mutations = {
  [SET_LEADERBOARDS](state, data) {
    let i = 0;
    for (const d of data.data) {
      data.data[i].total_duration_str = d.total_duration
        ? numberWithPoint(d.total_duration)
        : "-";
      i += 1;
    }
    state.leaderboards = data;
    return;
  },
  [SET_LEADERBOARDS_LIST](state, data) {
    let i = 0;
    for (const d of data.data) {
      data.data[i].total_duration_str = d.total_duration
        ? numberWithPoint(d.total_duration)
        : "-";
      i += 1;
    }
    let result = state.leaderboardsList;
    if (data.meta.page == 1) {
      result = data;
    } else {
      result.meta = data.meta;
      result.data.push(...data.data);
    }
    result.meta.max_current_page = data.meta.page * data.meta.limit;
    state.leaderboardsList = result;
    return;
  },
  [SET_LEADERBOARDS_OVERVIEW](state, data) {
    state.leaderboardsOverview = data;
    return;
  },
  [SET_LEADERBOARDS_HASHTAG_FILTERS](state, payload) {
    state.leaderboardsHashtagFilters = payload || [];
    return;
  },
  [SET_LEADERBOARDS_HASHTAG_ACTIVE_FILTERS](state, payload) {
    state.leaderboardsHashtagActiveFilters = payload || [];
    return;
  },
  [SET_LEADERBOARDS_PAGE](state, value) {
    state.leaderboardsPage = parseInt(value) || 1;
    return;
  },
  [SET_LEADERBOARDS_LIMIT](state, value) {
    state.leaderboardsLimit = parseInt(value) || 10;
    return;
  },
  [SET_LEADERBOARDS_TOTAL](state, value) {
    state.leaderboardsTotal = parseInt(value) || 0;
    return;
  },
  [SET_LEADERBOARDS_LOADING](state, value) {
    state.leaderboardsLoading = !!value;
    return;
  },
  [SET_LEADERBOARDS_LIST_LOADING](state, value) {
    state.leaderboardsListLoading = !!value;
    return;
  },
  [SET_LEADERBOARDS_METRICS](state, data) {
    state.leaderboardsMetrics = data;
    return;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
