import ApiService from "./api.service";

export const POST_REPORT = "postReport";

export const GET_REPORT_TYPES = "getReportTypes";

export const SET_REPORT_TYPES = "setReportTypes";

const state = {
  reportTypes: [],
};

const getters = {
  currentReportTypes(state) {
    return state.reportTypes;
  },
};

const actions = {
  [GET_REPORT_TYPES]({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.get("/report-types")
        .then(async ({ data }) => {
          await commit(SET_REPORT_TYPES, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_REPORT](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `/report?type=${params.type}&id=${params.id}`,
        params.data
      )
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_REPORT_TYPES](state, data) {
    state.reportTypes = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
