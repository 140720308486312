import ApiService from "./api.service";
import axios from "axios";

export const POST_TEST = "postTest";
export const GET_TESTS = "getTests";
export const GET_METRICS = "getMetrics";
export const GET_TEST = "getTest";
export const GET_TEST_SUMMARY = "getTestSummary";
export const GET_METRICS_SUMMARY = "getMetricsSummary";
export const GET_METRICS_SUMMARY_BYTAG = "getMetricsSummaryByTag";
export const UPLOAD_APP = "uploadApp";

export const SET_TESTS = "setTests";
export const SET_TEST = "setTest";
export const SET_TEST_SUMMARY = "setTestSummary";
export const SET_METRICS_SUMMARY = "setMetricsSummary";
export const SET_METRICS_SUMMARY_BYTAG = "setMetricsSummaryByTag";
export const SET_PROGRESS_BAR = "setProgressBar";
export const RESET_PROGRESS_BAR = "resetProgressBar";

export const GET_TEST_HASHTAG_FILTERS_ACTIVE = "getTestHashtagFiltersActive";
export const SET_TEST_HASHTAG_FILTERS = "setTestHashtagFilters";
export const GET_TEST_HASHTAG_FILTERS = "getTestHashtagFilters";

const numberWithPoint = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

const state = {
  tests: {},
  test: {},
  progressBar: {
    progressApp: 0,
  },
  testSummary: {},
  params: {},
  metricsSummary: {},
  hashtags: [],
  metricsSummaryByTag: [],
};

const getters = {
  currentTests(state) {
    return state.tests;
  },
  currentTest(state) {
    return state.test;
  },
  currentTestSummary(state) {
    return state.testSummary;
  },
  currentMetricsSummary(state) {
    return state.metricsSummary;
  },
  currentMetricsSummaryByTag(state) {
    return state.metricsSummaryByTag;
  },
  currentProgressBar(state) {
    return state.progressBar;
  },
  currentHashtags(state) {
    return state.hashtags;
  },
};

const actions = {
  [GET_TEST_HASHTAG_FILTERS_ACTIVE](context, val) {
    return new Promise((resolve, reject) => {
      const params = {
        tags: val,
        ...context.state.params,
      };
      ApiService.get("/metrics", params)
        .then(async ({ data }) => {
          data.params = params;
          context.commit(SET_TESTS, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_TEST_HASHTAG_FILTERS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("/test/hashtags", params)
        .then(async ({ data }) => {
          context.commit(SET_TEST_HASHTAG_FILTERS, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_TEST](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post("/test", params)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_TESTS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("/test", params)
        .then(async ({ data }) => {
          data.params = params;
          context.commit(SET_TESTS, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_METRICS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("/metrics", params)
        .then(async ({ data }) => {
          data.params = params;
          context.commit(SET_TESTS, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_TEST](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("/test/" + params)
        .then(async ({ data }) => {
          context.commit(SET_TEST, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_TEST_SUMMARY](context, app_type_id) {
    return new Promise((resolve, reject) => {
      ApiService.get("/test/summary", { app_type_id })
        .then(async ({ data }) => {
          context.commit(SET_TEST_SUMMARY, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_METRICS_SUMMARY](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("/metrics/summary", params)
        .then(async ({ data }) => {
          context.commit(SET_METRICS_SUMMARY, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_METRICS_SUMMARY_BYTAG](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("/metrics/summary", params)
        .then(async ({ data }) => {
          data.data.label = params.label;
          data.data.tag = params.tags;
          context.commit(SET_METRICS_SUMMARY_BYTAG, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPLOAD_APP](context, data) {
    return new Promise((resolve, reject) => {
      // let progressName = data.progressName.split("+");
      // if (progressName.length > 1) {
      //   state.progressBar[progressName[0]][progressName[1]] = 0;
      // }
      state.progressBar[data.progressName] = 0;
      let formData = new FormData();
      formData.append("file", data.data);
      axios
        .post("upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          cancelToken: data.cancelToken.token,
          onUploadProgress: function (progressEvent) {
            context.commit(SET_PROGRESS_BAR, {
              data: parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              ),
              progressName: data.progressName,
            });
          }.bind(this),
        })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [RESET_PROGRESS_BAR](context, data) {
    context.commit(SET_PROGRESS_BAR, {
      data: 0,
      progressName: data.progressName,
    });
  },
};

const mutations = {
  [SET_TESTS](state, data) {
    let i = 0;
    for (const d of data.data) {
      data.data[i].total_duration_str = d.total_duration
        ? numberWithPoint(d.total_duration)
        : "-";
      i += 1;
    }
    let result = state.tests;
    if (data.meta.page == 1) {
      result = data;
    } else {
      result.meta = data.meta;
      result.data.push(...data.data);
    }
    result.meta.max_current_page = data.meta.page * data.meta.limit;
    state.tests = result;
    state.params = data.params;
    return;
  },
  [SET_TEST](state, data) {
    let rank = "-";
    let total_participants = "-";
    if (data.current_rank_used) {
      const rankArr = data.current_rank_used.split("of");
      rank = rankArr[0].trim();
      total_participants = rankArr[1].trim();
    }
    data.rank = rank;
    data.total_participants = total_participants;

    const isProgress = ["RUNNING", "QUEUING"].includes(data.job_status);
    const isPlayground = data.type === "PLAYGROUND";
    data.is_evaluate = isPlayground && isProgress;
    if (data.result && data.result.detail) {
      // new output version
      data.result_test = data.result
        ? data.result.detail.map((row) => {
            row.tests = row.tests
              ? row.tests.map((test) => {
                  test.suites_str = test.suites.replace(
                    "/*",
                    "<br><span style='color: #000; font-weight: bold'>Poin "
                  );
                  test.suites_str = test.suites_str.replace("*/", "</span>");
                  if (test?.language) {
                    test.suites_str += `(${test.language})`;
                  }
                  test.title = test.suites.split("/*")[0];
                  test.point = parseInt(test.suites.split("/*")[1]);
                  return test;
                })
              : [];
            return row;
          })
        : [];
    } else if (data.result.totalPoint == 0) {
      // default value, it means no result created
      // do nothing
    } else {
      // old output version
      const keys = Object.keys(data.result);
      let resultTest = [];
      for (const d of keys) {
        if (
          d != "failed" &&
          d != "passed" &&
          d != "totalDuration" &&
          d != "aspectSummary"
        ) {
          // const titleArr = d.split(" ");
          // const title = titleArr.slice(3, titleArr.length).join(" ");
          let i = 0;
          for (const e of data.result[d]) {
            // const suitesArr = e.suites.split(" ");
            // data.result[d][i].suites_str = suitesArr
            //   .slice(1, e.suites.length)
            //   .join(" ");
            data.result[d][i].suites_str = e.suites.replace(
              "/*",
              "<br><span style='color: #000; font-weight: bold'>Poin "
            );
            data.result[d][i].suites_str = data.result[d][i].suites_str.replace(
              "*/",
              "</span>"
            );
            i += 1;
          }
          resultTest.push({
            name: d,
            result: data.result[d],
          });
        }
      }
      data.result_test = resultTest;
    }

    data.passed_score =
      Math.round(
        (data.result.passed / (data.result.passed + data.result.failed)) * 1000
      ) / 10;
    state.test = data;
    return;
  },
  [SET_PROGRESS_BAR](state, data) {
    // let progressName = data.progressName.split("+");
    // if (progressName.length > 1) {
    //   state.progressBar[progressName[0]][progressName[1]] = data.data;
    // } else {
    state.progressBar[data.progressName] = data.data;
    // }
  },
  [SET_TEST_SUMMARY](state, data) {
    state.testSummary = data;
  },
  [SET_METRICS_SUMMARY](state, data) {
    state.metricsSummary = data;
  },
  [SET_METRICS_SUMMARY_BYTAG](state, data) {
    state.metricsSummaryByTag.push(data);
  },
  [SET_TEST_HASHTAG_FILTERS](state, data) {
    state.hashtags = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
