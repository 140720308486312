import ApiService from "./api.service";

export const GET_HASHTAGS_LIST = "getHashtagsList";
export const GET_HASHTAGS_GROUP = "getHashtagsGroup";
export const HANDLE_ACTIVE_GROUP = "handleActiveGroup";
export const LOADMORE_HASHTAGS = "loadMoreHashtags";

export const SET_HASHTAGS_LIST = "setHashtagsList";
export const SET_HASHTAGS_GROUP = "setHashtagsGroup";
export const SET_ACTIVE_GROUP = "setActiveGroup";
export const SET_HASHTAGS_LOADING = "setHashtagsLoading";
export const SET_HASHTAGS_LOADING_MORE = "setHashtagsLoadingMore";
export const SET_HASHTAGS_PAGE = "setHashtagsPage";
export const SET_HASHTAGS_TOTAL = "setHashtagsTotal";
export const SET_HASHTAGS_LIMIT = "setHashtagsLimit";
export const RESET_HASHTAGS_STATE = "resetHashtagsState";

const state = {
  loading: false,
  loadingMore: false,
  page: 1,
  total: 0,
  limit: 10,
  hashtagsList: [],
  hashtagsGroup: [],
  activeGroup: null,
};

const getters = {
  hashtagsList(state) {
    return state.hashtagsList;
  },
  hashtagsGroup(state) {
    return state.hashtagsGroup;
  },
  activeGroup(state) {
    return state.activeGroup;
  },
  isLoadingHashtags(state) {
    return state.loading;
  },
  isLoadingMoreHashtags(state) {
    return state.loadingMore;
  },
  hashtagsPage(state) {
    return state.page;
  },
  hashtagsTotal(state) {
    return state.total;
  },
  hashtagsLimit(state) {
    return state.limit;
  },
};

const actions = {
  [GET_HASHTAGS_LIST](context) {
    return new Promise((resolve, reject) => {
      let appTypeId = context.rootGetters.currentAppType.id;
      let limit = context.getters.hashtagsLimit;
      let page = context.getters.hashtagsPage;
      let hashtagGroupId = context.getters.activeGroup;
      let params = { page, limit, appTypeId };

      if (hashtagGroupId) params["hashtagGroupId"] = hashtagGroupId;
      ApiService.get("/hashtags/app-challenger", params)
        .then(async ({ data: { data, meta } }) => {
          await context.commit(SET_HASHTAGS_LIST, { data });
          await context.commit(SET_HASHTAGS_TOTAL, meta.total);
          await context.commit(SET_HASHTAGS_LIMIT, meta.limit);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_HASHTAGS_GROUP](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("/hashtags/groups")
        .then(async ({ data: { data } }) => {
          context.commit(SET_HASHTAGS_GROUP, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async [HANDLE_ACTIVE_GROUP](context, value) {
    context.commit(SET_ACTIVE_GROUP, value);
    context.commit(SET_HASHTAGS_PAGE, 1);
    context.commit(SET_HASHTAGS_LIST, { data: [], replaceCurrent: true });
    context.commit(SET_HASHTAGS_LOADING, true);
    await context.dispatch(GET_HASHTAGS_LIST);
    context.commit(SET_HASHTAGS_LOADING, false);
  },
  async [LOADMORE_HASHTAGS](context) {
    context.commit(SET_HASHTAGS_LOADING_MORE, true);
    context.commit(SET_HASHTAGS_PAGE, context.state.page + 1);
    await context.dispatch(GET_HASHTAGS_LIST);
    context.commit(SET_HASHTAGS_LOADING_MORE, false);
  },
};

const mutations = {
  [SET_HASHTAGS_LIST](state, { data, replaceCurrent }) {
    let current = state.hashtagsList;
    if (replaceCurrent) {
      state.hashtagsList = data;
    } else {
      state.hashtagsList = [...current, ...data];
    }
    return;
  },
  [SET_HASHTAGS_GROUP](state, data) {
    state.hashtagsGroup = data;
    return;
  },
  [SET_ACTIVE_GROUP](state, value) {
    state.activeGroup = value || null;
    return;
  },
  [SET_HASHTAGS_LOADING](state, value) {
    state.loading = !!value;
    return;
  },
  [SET_HASHTAGS_LOADING_MORE](state, value) {
    state.loadingMore = !!value;
    return;
  },
  [SET_HASHTAGS_PAGE](state, value) {
    state.page = parseInt(value) || 1;
    return;
  },
  [SET_HASHTAGS_TOTAL](state, value) {
    state.total = parseInt(value) || 0;
    return;
  },
  [SET_HASHTAGS_LIMIT](state, value) {
    state.limit = parseInt(value) || 2;
    return;
  },
  [RESET_HASHTAGS_STATE](state) {
    state.page = 1;
    state.total = 0;
    state.hashtagsList = [];
    state.hashtagsGroup = [];
    state.activeGroup = null;
    return;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
