import ApiService from "./api.service";

export const GET_APP_TYPE_VIEW = "getAppTypeView";

export const SET_APP_TYPE_VIEW = "setAppTypeView";

const state = {
  appTypeView: {},
};

const getters = {
  currentAppTypeView(state) {
    return state.appTypeView;
  },
};

const actions = {
  [GET_APP_TYPE_VIEW](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put("/app-type-view", params)
        .then(async ({ data }) => {
          await context.commit(SET_APP_TYPE_VIEW, data.data);
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_APP_TYPE_VIEW](state, data) {
    state.appTypesView = data;
    return;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
