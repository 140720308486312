import ApiService from "./api.service";

export const GET_JOBS_FILTER = "getJob";
export const SET_JOBS_FILTER = "setJobsFilter";

const state = {
  jobFilters: [],
};

const getters = {
  currentJobFilters(state) {
    return state.jobFilters;
  },
};

const actions = {
  [GET_JOBS_FILTER](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.getJob("/job?valid_date=now&query=" + params.query, params)
        .then(({ data }) => {
          context.commit(SET_JOBS_FILTER, data);
          resolve(data);
        })
        .catch((err) => {
          console.error("Error fetching jobs:", err);
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_JOBS_FILTER](state, data) {
    state.jobFilters = data.data.results;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
