import axios from "axios";
import JwtService from "../jwt.service";
import config from "@/ConfigProvider";
import axiosCookieJarSupport from "axios-cookiejar-support";
import { CookieJar } from "tough-cookie";

const ApiService = {
  init() {
    axios.defaults.baseURL = config.value("API_URL_V1");
  },

  setHeader() {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return axios.get(resource, params).catch((error) => {
      throw new Error(`ApiService ${error}`);
    });
  },

  async get(resource, params) {
    if (params && params.session) {
      axiosCookieJarSupport(axios);
      const cookieJar = new CookieJar();
      return axios
        .get(`${resource}`, {
          jar: cookieJar,
          withCredentials: true,
        })
        // .catch((error) => {
        //   throw new Error(`ApiService ${error}`);
        // });
    } else {
      return axios.get(`${resource}`, { params })
      // .catch((error) => {
      //   const err = new Error(`ApiService ${error}`);
      //   err.code = error.response.status;
      //   throw err;
      // });
    }
  },

  post(resource, params) {
    return axios.post(`${resource}`, params);
  },

  upload(resource, params) {
    let formData = new FormData();
    formData.append("file", params);
    return axios.post(`${resource}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  update(resource, slug, params) {
    return axios.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return axios.put(`${resource}`, params);
  },

  patch(resource, params) {
    return axios.patch(`${resource}`, params);
  },

  delete(resource) {
    return axios.delete(resource).catch((error) => {
      throw new Error(`ApiService ${error}`);
    });
  },
};

export default ApiService;
