import ApiService from "./api.service";

export const POST_LIKE = "postLike";

export const GET_LIKES = "getLikes";

export const SET_RESULT_LIKES = "setResultLikes";
export const SET_RESULT_LIKED_BY_USER = "setResultLikedByUser";

export const REMOVE_LIKE = "removeLike";

const state = {
  resultLikes: [],
  resultLikedByUser: false,
};

const getters = {
  currentResultLikes(state) {
    return state.resultLikes;
  },
  resultLikedByUser(state) {
    return state.resultLikedByUser;
  },
};

const actions = {
  [GET_LIKES]({ commit }, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/likes?type=${params.type}&id=${params.id}`)
        .then(async ({ data }) => {
          await commit(SET_RESULT_LIKES, data.data);
          await commit(SET_RESULT_LIKED_BY_USER, data.isLikedByUser);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_LIKE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/likes?type=${params.type}&id=${params.id}`)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [REMOVE_LIKE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/likes?type=${params.type}&id=${params.id}`)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_RESULT_LIKES](state, data) {
    state.resultLikes = data;
  },
  [SET_RESULT_LIKED_BY_USER](state, data) {
    state.resultLikedByUser = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
