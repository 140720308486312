import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Chakra, { CThemeProvider, CReset } from "@chakra-ui/vue";
import ApiService from "@/store/api.service";
// import { VERIFY_AUTH } from "@/store/auth.module";
import customTheme from "./custom-theme.js";
import ClickOutside from "vue-click-outside";

import "@/plugins/vue-meta";
import {
  faUpload,
  faFilter,
  faArrowUp,
  faArrowLeft,
  faClock,
  faBook,
} from "@fortawesome/free-solid-svg-icons";
import VueSocialSharing from "vue-social-sharing";
import OneSignal from "onesignal-vue";
import VueApexCharts from "vue-apexcharts";
import config from "@/ConfigProvider";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import VueCroppie from "vue-croppie";
import "croppie/croppie.css";

if (
  config.value("APPLICATIONINSIGHTS_CONNECTION_STRING") &&
  config.value("APPLICATIONINSIGHTS_CONNECTION_STRING") != ""
) {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: config.value("APPLICATIONINSIGHTS_CONNECTION_STRING"),
    },
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView();
}

Vue.use(Chakra, {
  extendTheme: customTheme,
  icons: {
    iconPack: "fa",
    iconSet: {
      faUpload,
      faFilter,
      faArrowUp,
      faArrowLeft,
      faClock,
      faBook,
    },
  },
});

Vue.use(VueSocialSharing);
Vue.use(OneSignal);
Vue.use(VueCroppie);
Vue.directive("click-outside", ClickOutside);

// apex chart
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

ApiService.init();
// router.beforeEach(async (to, from, next) => {
//   await Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);
// });
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(CThemeProvider, [h(CReset), h(App)]),
}).$mount("#app");
